.new-design {
    .main-sidebar {
        background-color: $secondary-color-black;
        padding-top: 12rem;
        width: 250px;
        .sidebar{
            .sidebar-menu{
                li{
                    border-left: 3px solid transparent;
                    transition: all .3s;
                    a{
                        display: flex;
                        align-items: center;
                        padding: 12px 10px 12px 25px ;
                        color: $white;
                        span{
                            font-size: 1.4rem;
                            font-weight: 500;
                            padding-left: 12px;
                            margin-top: 0;
                        }
                        &:hover{
                            color: $white !important;
                            span{
                            color: $white !important;
                            }
                        }
                    }
                    &:hover{
                        background-color: rgba($white, .1);
                        border-color: $primary-color-skyblue;
                    }
                }
            }
        }
    }
}