.header-gradient{
    .main-header{
        .mainlogo{
            width: 250px;
            .logo{
                padding: 17px 0px;
                width: 250px;
            }
        }
        .navbar{
            @include gradient1;
            min-height: 70px;
            box-shadow: none;
            .dropdown{
                a{
                    background-color: transparent !important;
                    &:hover{
                    background-color: transparent !important;
                    }
                    .avatar-image{
                        height: 30px;
                    }
                }
                .dropdown-menu{
                    right:0;
                    left:auto;
                    margin-right: 15px;
                }
            }
        }
    }
}