.modal-header {
  padding: 20px;
}

.modal-body {
  padding: 20px;

  .form-control {
    display: block;
    width: 100%;
    height: 3.25rem;
    padding: .5rem .75rem;
    font-size: 1.475rem;
    font-weight: 400;
    line-height: 1.3125;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .35rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}