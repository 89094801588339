.course-list {

  .form-group {
    label {
      color: $title-color;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  select.form-control {
    color: $title-color;
    font-weight: 600;
  }

  .vcard-container {
    .vcard {
      width: 100%;
      max-height: 450px;
      min-height: 369px;
      margin: 0;
      box-shadow: none;
      border: 1px solid $border;
      padding-bottom: 57px;
      border-radius: 4px;

      .vcard-header {
        position: relative;
        flex: 1;
        color: $title-color;
        font-size: 1.6rem;
        font-weight: 600;

        .small {
          .course-keywords {
            color: $secondary-text-color;
          }
        }
      }

      .vcard-body {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 15px 15px;

        .label-danger {
          background-color: rgba($primary-color-red, .2) !important;
          color: $primary-color-red !important;
          font-weight: 400 !important;
          padding: .4em .7em .4em !important;
        }

        .label-success {
          background-color: rgba($primary-color-skyblue, .2) !important;
          color: $primary-color-skyblue !important;
          font-weight: 400 !important;
          padding: .4em .7em .4em !important;
        }

        .label-default {
          background-color: transparent;
          padding: .6em .6em .1em;
        }
      }

      .vcard-image {
        max-width: 100%;
      }

      .course-image {
        flex: 1;
        height: 200px;
        overflow: hidden;
      }

      .vcard-links {
        top: 0;
        display: block;
        z-index: 3;
        bottom: auto;

        .btn-link {
          background-color: $primary-color-skyblue;
          color: $white;
          border-radius: 50%;
        }
      }

      .card-content-area {
        padding: 10px;
      }
    }
  }
}


.editcourses {
  .tabbar {
    .nav-tabs {
      border-top: 1px solid $secondary-color-lightwhite;
      border-bottom-color: $secondary-color-lightwhite;
      display: flex;
      padding-right: 30px;
      padding-left: 30px;
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;

      li {
        &.active {
          a {
            color: $primary-color-skyblue;
            border-bottom-color: $primary-color-skyblue;
          }
        }

        a {
          border-bottom-width: 3px;
          border-bottom-color: transparent;
          padding: 13px 10px 12px 10px;
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
    }

    .tab-content {
      padding: 20px 25px;

      .section-title {
        font-size: 1.8rem;
        font-weight: 600;
        color: $title-color;
      }
    }
  }

  .chapter-list {
    background-color: $white;
    margin-top: 3rem;

    .chapter-title {
      padding: 20px 26px 10px;
    }

    .course-item {
      padding: 29px 26px;
      border-bottom-color: $secondary-color-lightwhite;

      .course-item-img {
        img {
          max-height: 150px;
          width: 100%;
        }
      }

      .course-item-title {
        color: $title-color;
        font-size: 1.8rem;
        font-weight: 600;
      }

      .settings {
        .setting {
          font-weight: 600;
          font-size: 1.4rem;
          &.check_answer{
            color: $primary-color-skyblue;
          }
          &:not(.check_answer) {
            color: #7F8FA4;
          }
        }
      }
    }
  }
}