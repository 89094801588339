.new-design {
    .main-footer {
        margin-left: 250px;
        font-size: 1.2rem;
        color: #222C3C;
        font-weight: 400;
        border: none;
        box-shadow: 0 -1px 0 1px #E6EAEE;
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(100% - 251px);

        a {
            color: $primary-color-skyblue;
        }
    }
}