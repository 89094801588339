form{
    label{
        font-weight: 400;
        color: #222C3C;
        text-align:right !important;
        letter-spacing: 0 !important;
        a{
            color: $primary-color-lightskyblue;
        }
    }
    
}
.form-control{
    border-color: $border;
    border-radius: 4px;
    // color: $color1;
    &:focus{
        // color: $color1;
        border-color: $border;
    }
}
