.btn{
    &.btn-primary{
        @include gradient2;
        border-radius: 4px;
        border-color: $skyblue-border;
    }
    &.btn-success{
        @include gradient3;
    }
    &.btn-danger{
        @include gradient4;
    }
}