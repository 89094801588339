.authentication-page{
    .card{
        .card-img-top{
            height: 135px;
            width: auto;
        }   
    }
    .card-body{
        .text-muted{
            color: $white !important;
        }
    }
}
