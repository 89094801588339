.table {
    &.table-bordered {
        thead {
            tr {
                th {
                    border: none;
                    padding: 14px 8px;

                    &:not(:first-child):not(:last-child) {
                        text-align: left;

                    }
                }
            }
        }

        tbody {
            tr {

                &:nth-child(odd) {
                    background-color: #F5F7F9;
                }

                td {
                    border: none;
                    padding: 18px 8px;
                    vertical-align: middle;
                    color: #192A3E;

                    &:first-child {
                        text-align: center;
                    }
                }
            }
        }

        .btn {
            padding: 4px 8px;

            &.btn-success {
                margin-right: 2px;
            }
        }
    }
}

.ng-table-pager {
    
}
.pagination {
    box-shadow: 0 1px 1px 1px $lightgrey;
    background-color: $white;
    padding: 1px;

    li {
        background-color: $white;

        a {
            margin: 2px 1px;
            border: none;
            border-radius: 4px;
            background-color: transparent;

            &:hover {
                @include gradient2;
            }
        }

        &.active {
            a {
                @include gradient2;
            }
        }

        &:first-child {
            a {
                border-right: 1px solid $lightgrey;
            }
        }

        &:last-child {
            a {
                border-left: 1px solid $lightgrey;
            }
        }
    }
}