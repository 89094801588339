body{
    font-family: $body-font;
    font-size: 14px;
}

h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: $body-font;
  }
.pagination{
    margin-bottom: 0;
}

.vv-main{
    background-color: #E5E5E5;
}

.site-title{
    font-size: 2.2rem;
    font-weight: 500;
    color: $title-color;
}

.text-primary{
  color: $primary-color-skyblue;
}