.breadcrumb-style{
    .btn-link{
        color: $primary-color-skyblue;
        font-size: 1.2rem;
        &:focus,
        &:hover{
            text-decoration: none;
        }
        &:not(.disabled){
            padding-right: 6px;
        }
        &.disabled{
            color: #7F8FA4;
        }
    }
}