// primary colors

$primary-color-red: #FB232C;
$primary-color-blue: #0029FF;
$primary-color-purple: #6B33FF;
$primary-color-yellow: #FFDB00;
$primary-color-magenta: #9B34BD;
$primary-color-skyblue: #00AEF3;
$primary-color-lightskyblue: #2D9CDB;

// secondary color
$secondary-color-black: #221F20;
$secondary-color-lightblack: #393C3E;
$secondary-color-grey: #71787E;
$secondary-color-lightgrey: #BBC5D5;
$secondary-color-lightwhite: #E6EAEE;
$white: #ffffff;
$title-color: #222C3C;
$color1: #B7C0CD;
$border: #CED0DA;
$skyblue-border: #1985D7;

$lightgrey:#D8DCE6;

$body-font: 'Montserrat', sans-serif;

@mixin gradient1 {
    // useage: @include gradient1;
    background: rgba(255, 221, 0, 1);
    background: -moz-linear-gradient(-45deg, rgba(255, 221, 0, 1) 0%, rgba(251, 35, 43, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255, 221, 0, 1)), color-stop(100%, rgba(251, 35, 43, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(255, 221, 0, 1) 0%, rgba(251, 35, 43, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(255, 221, 0, 1) 0%, rgba(251, 35, 43, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255, 221, 0, 1) 0%, rgba(251, 35, 43, 1) 100%);
    background: linear-gradient(135deg, rgba(255, 221, 0, 1) 0%, rgba(251, 35, 43, 1) 100%);
}

@mixin gradient2 {
    // useage: @include gradient1;
    background: rgba(79, 175, 249, 1);
    background: -moz-linear-gradient(top, rgba(79, 175, 249, 1) 0%, rgba(25, 144, 235, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(79, 175, 249, 1)), color-stop(100%, rgba(25, 144, 235, 1)));
    background: -webkit-linear-gradient(top, rgba(79, 175, 249, 1) 0%, rgba(25, 144, 235, 1) 100%);
    background: -o-linear-gradient(top, rgba(79, 175, 249, 1) 0%, rgba(25, 144, 235, 1) 100%);
    background: -ms-linear-gradient(top, rgba(79, 175, 249, 1) 0%, rgba(25, 144, 235, 1) 100%);
    background: linear-gradient(to bottom, rgba(79, 175, 249, 1) 0%, rgba(25, 144, 235, 1) 100%);
    border-color: #1985D7;
}

@mixin gradient3 {
    // useage: @include gradient3;
    background: rgba(66, 214, 88, 1);
    background: -moz-linear-gradient(top, rgba(66, 214, 88, 1) 0%, rgba(52, 170, 68, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(66, 214, 88, 1)), color-stop(100%, rgba(52, 170, 68, 1)));
    background: -webkit-linear-gradient(top, rgba(66, 214, 88, 1) 0%, rgba(52, 170, 68, 1) 100%);
    background: -o-linear-gradient(top, rgba(66, 214, 88, 1) 0%, rgba(52, 170, 68, 1) 100%);
    background: -ms-linear-gradient(top, rgba(66, 214, 88, 1) 0%, rgba(52, 170, 68, 1) 100%);
    background: linear-gradient(to bottom, rgba(66, 214, 88, 1) 0%, rgba(52, 170, 68, 1) 100%);
    border-color: #2CB33F;
}

@mixin gradient4 {
    // useage: @include gradient3;
    background: rgba(251, 35, 43, 1);
    background: -moz-linear-gradient(top, rgba(251, 35, 43, 1) 0%, rgba(202, 3, 10, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(251, 35, 43, 1)), color-stop(100%, rgba(202, 3, 10, 1)));
    background: -webkit-linear-gradient(top, rgba(251, 35, 43, 1) 0%, rgba(202, 3, 10, 1) 100%);
    background: -o-linear-gradient(top, rgba(251, 35, 43, 1) 0%, rgba(202, 3, 10, 1) 100%);
    background: -ms-linear-gradient(top, rgba(251, 35, 43, 1) 0%, rgba(202, 3, 10, 1) 100%);
    background: linear-gradient(to bottom, rgba(251, 35, 43, 1) 0%, rgba(202, 3, 10, 1) 100%);
    border-color: #A5142F;
}



// $primary-color: #ffffff;
$sidebar-color: #000000;
$breadcrumb-color: #5c5cb5;

$secondary-text-color: #A9A9A9;